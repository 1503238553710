import './App.css';
import React, { useState, useEffect } from 'react';
import { Slider, Intent, Spinner } from "@blueprintjs/core";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';

const REDIRECT_URI = 'https://jerryneedsquiet.com/callback';

function App() {
  const [access, setAccess] = useState(null);
  const [percentage, setPercentage] = useState(null);
  const [notPlaying, setNotPlaying] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (window.location.pathname === '/callback') {
      const urlSearch = new URLSearchParams(window.location.search);
      const code = urlSearch.get('code');
      fetch("https://accounts.spotify.com/api/token", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic OWRkYjk4NGZlOTQwNGI4NmFlZTg3M2QxZWE4MDMyNTU6N2MzODEwZGYzZTZjNDJjN2E2M2IyYTJlZTAxZDI0YmM='
        },
        body: 'grant_type=authorization_code&code=' + code + '&redirect_uri=' + REDIRECT_URI
      })
      .then(response => response.json())
      .then(result => {
        console.log(result.refresh_token);

        fetch("https://monarchus.herokuapp.com/mula/create", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            refresh_token: result.refresh_token
          })
        })
        .then(response => response.json())
        .then(result => {
          window.location.replace(window.location.origin + "/" + result.word_path + '?message=true')
        })        
      })
    } else if (window.location.pathname !== '/') {
      let word_path = window.location.pathname.substr(1);
      fetch("https://monarchus.herokuapp.com/mula/detail/" + word_path)
      .then(response => response.json())
      .then(result => {
        if (!result.refresh_token) {
          setNotFound(true);
        } else {
          fetch("https://accounts.spotify.com/api/token", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization': 'Basic OWRkYjk4NGZlOTQwNGI4NmFlZTg3M2QxZWE4MDMyNTU6N2MzODEwZGYzZTZjNDJjN2E2M2IyYTJlZTAxZDI0YmM='
            },
            body: 'grant_type=refresh_token&refresh_token=' + result.refresh_token
          })
          .then(response => response.json())
          .then(result => {
            if (result.refresh_token) {
              fetch("https://monarchus.herokuapp.com/mula/detail/" + word_path, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: {
                  refresh_token: result.refresh_token
                }
              })
            }
            setAccess(result.access_token);

            fetch("https://api.spotify.com/v1/me/player", {
              headers: {
                'Authorization': 'Bearer ' + result.access_token
              }
            })
            .then(response => {
              if (!response.headers.get("content-type")) {
                setNotPlaying(true);
              } else {
                response.json().then(result => setPercentage(result['device']['volume_percent']))
              }
            });
            fetch("https://api.spotify.com/v1/me", {
              headers: {
                'Authorization': 'Bearer ' + result.access_token
              }
            })
            .then(response => response.json().then(result => setDisplayName(result['display_name'])));
          });
        }
      });
    }
  }, []);

  const urlSearch = new URLSearchParams(window.location.search);

  return (
    <Router>
      <div className="App" style={{width: 500, maxWidth: '90%', margin: 'auto', marginTop: 200}}>
      <Switch>
        <Route path='/callback'>
          <Spinner intent={Intent.PRIMARY} size={60}  />

        </Route>
        <Route exact path='/'>
          <h1 style={{fontSize: 42, color: 'white'}}>Give Your Roommate a Volume Knob</h1>
          <div style={{fontSize: 16, color: '#ffffffba'}}>Connect your Spotify account and we'll give you a unique link that you can share with your roommates so they can remotely adjust your volume!</div>
          <a href="https://accounts.spotify.com/authorize?client_id=9ddb984fe9404b86aee873d1ea803255&response_type=code&redirect_uri=https%3A%2F%2Fjerryneedsquiet.com%2Fcallback&scope=user-read-playback-state%20user-modify-playback-state%20user-read-email" >
            <img 
              alt="connect to spotify"
              style={{width: 300, marginTop: 30}}
              src="/image-asset.png" 
            />
          </a>
        </Route>
        <Route path='/*'>
          <div>
            
              {notFound &&  <div>Not Found</div>}
              {notPlaying && <div style={{fontSize: 24, color: 'white'}}>{displayName}'s Spotify is not currently playing</div>}
              {(percentage || percentage === 0) &&
                <div>
                  <h1 style={{marginBottom: 40, color: 'white'}}>Controlling <b>{displayName}</b>'s Spotify</h1>
                  <Slider
                    min={0}
                    max={100}
                    stepSize={1}
                    labelStepSize={10}
                    onChange={(val) => {
                      if (percentage !== val) {
                        setPercentage(val);
                        fetch("https://api.spotify.com/v1/me/player/volume?volume_percent=" + val.toString(), {
                          method: 'PUT',
                          headers: {
                            'Authorization': 'Bearer ' + access
                          }
                        });
                      }
                      
                    }}
                    value={percentage}
                  />

                  
                </div>
                
              }
              {urlSearch.get('message') &&
                <div> 
                  <div style={{marginTop: 100, color: 'white'}}>Share this URL with your roommates: (click to copy)</div>
                  <CopyToClipboard text={window.location.href.split('?')[0]}
                    onCopy={() => setCopied(true)}>
                    <div style={{cursor: 'pointer', color: '#ffffffa3', marginTop: 10}}>{window.location.href.split('?')[0]}</div>
                  </CopyToClipboard>
                  {copied && <div style={{color: 'white', marginTop: 10}}>Copied!</div>}
                  </div>
              }
          </div>
        </Route>
       
      </Switch>
      </div>
      <div style={{position: 'fixed', bottom: 0, height: 40, width: '100%', backgroundColor: 'black', color: 'white', textAlign: 'center', paddingTop: 10}}>
        Made with ❤️ by <a style={{color: 'white', textDecoration: 'underline'}} href="https://twitter.com/rishab_hegde">Rishab Hegde</a>
      </div>

    </Router>
    
  );
}

export default App;
